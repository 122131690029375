<template>
    <BaseModal size="xl" title="Chi tiết Phụ lục Đề xuất Chương trình Xúc tiến đầu tư" :show="isPhuLucModalOpen"
               @update:show="value => toggleModal({show:value})">
      <template #default>
        <div>
          <div class="w-100">
            <div class="pdf-wrapper">
              <div>
                <div class="">
                  <div class="pdf-page fs-16">
                    <div id="pdf1" class="pdf-content">
                      <div class="text-center fs-16">
                        <strong class="bold">Mẫu C.II.2</strong><br>
                        <strong>Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư hàng năm</strong><br>
                        <p class="font-italic">(Áp dụng đối với chương trình xúc tiến đầu tư của Bộ/Ủy ban nhân dân cấp
                          tỉnh theo quy định tại Điểm a, Khoản 2, Điều 93 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                        <hr>
                      </div>
                      <div class="row fs-16">
                        <div class="col-md-6">
                          <b class="text-uppercase">{{ detailItem.tenBo_UBNDTinh }}</b>
                        </div>
                        <div class="col-md-6">
                          <p class="font-weight font-italic" style="float: right; display: flex">
                          <span class="mt-2">{{ detailItem.diaDiem }}, ngày {{
                              getDay(detailItem.ngayVanBan)
                            }} tháng {{
                              getMonth(detailItem.ngayVanBan)
                            }} năm {{ getYear(detailItem.ngayVanBan) }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="text-center">
                        <b class="text-uppercase">Biểu tổng hợp dự kiến chương trình xúc tiến đầu tư năm
                          {{ Number(getYear(detailItem.ngayVanBan)) + 1 }}</b>
                        <p class="font-italic">(Kèm theo Văn bản dự kiến Chương trình xúc tiến đầu tư năm
                          {{ Number(getYear(detailItem.ngayVanBan)) + 1 }} của Bộ/UBND cấp tỉnh {{ detailItem.tenBo_UBNDTinh }} tại
                          công văn số {{ detailItem.soVanBan }} ngày {{ getDay(detailItem.ngayVanBan) }} tháng {{
                            getMonth(detailItem.ngayVanBan)
                          }} năm {{ getYear(detailItem.ngayVanBan) }})</p>
                      </div>
                      <div class="float-right" style="display: flex">
                        <p>Đơn vị: triệu VNĐ</p>
<!--                        <button class="btn btn-behance" @click="addHoatDong">Thêm hoạt động</button>-->
                      </div>
                      <div class="">
                        <vue-scrolling-table>
                          <template slot="thead" class="bg-secondary">
                            <tr class="bg-secondary">
                              <!--                              <th class="text-center align-middle fs-12 w-50px" rowspan="2">-->
                              <!--                                <i class="c-icon cil-options rotate90"></i>-->
                              <!--                              </th>-->
                              <th class="text-center align-middle fs-12 w-50px" rowspan="3">
                                STT
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Tên hoạt động</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Loại hoạt động</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Dự kiến thời gian tổ chức thực hiện
                                (tháng, năm)
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Đơn vị chủ trì thực hiện</th>
                              <th class="p-1 text-center fs-13 w-120" colspan="2">Địa điểm tổ chức</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Mục đích/Nội dung hoạt động</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Ngành/Lĩnh vực kêu gọi đầu tư (ngành
                                ISIC cấp 2)
                              </th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Địa bàn/Tỉnh/Vùng kêu gọi đầu tư</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Căn cứ triển khai hoạt động</th>
                              <th class="p-1 text-center fs-13 w-120" colspan="4">Đơn vị phối hợp</th>
<!--                              <th class="p-1 text-center fs-13 w-120" rowspan="3">Nguồn kinh phí</th>-->
                              <th class="p-1 text-center fs-13 w-120" colspan="3">Kinh phí</th>
                              <th class="p-1 text-center fs-13 w-90px" rowspan="3">Ghi chú</th>
                            </tr>
                            <tr class="bg-secondary">
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Trong nước (địa phương)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Nước ngoài (quốc gia)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tên tổ chức/Cơ quan trong nước</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tên tổ chức/Cơ quan nước ngoài</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Doanh nghiệp trong nước</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Doanh nghiệp nước ngoài</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Ngân sách địa phương</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Khác (tài trợ)</th>
                              <th class="p-1 text-center fs-13 w-120" rowspan="2">Tổng kinh phí</th>
                            </tr>
                            <!--                            <tr class="bg-secondary">-->
                            <!--                              <th class="p-1 text-center fs-13 w-90px">Trong nước (tên doanh nghiệp)</th>-->
                            <!--                              <th class="p-1 text-center fs-13 w-90px">Nước ngoài (tên doanh nghiệp)</th>-->
                            <!--                            </tr>-->
                          </template>
                          <template slot="tbody">
                            <template v-if="detailItem.chiTietDeXuatCTXTDT.length">
                              <tr v-for="(it, indx) in detailItem.chiTietDeXuatCTXTDT" v-bind:key="it.id">
                                <td class="text-center p-1 fs-13 align-middle w-50px">{{ indx + 1 }}</td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.tenHoatDong"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select disabled class="form-control" v-model="it.loaiHoatDongId" style="background-color: white">
                                    <option :value="itemz.value" v-for="itemz in optionsLoaiHoatDong"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <v-date-picker class="" v-model="it.duKienThoiGianToChuc" locale="vi">
                                    <template v-slot="{ inputValue, inputEvents }">
                                      <input
                                        disabled
                                        class="form-control"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        style="background-color: white"
                                      />
                                    </template>
                                  </v-date-picker>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.donViChuTriThucHien"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select disabled class="form-control" v-model="it.diaDiem_TrongNuocId" style="background-color: white">
                                    <option :value="itemz.value" v-for="itemz in optionsTinhThanh"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select disabled class="form-control" v-model="it.diaDiem_NuocNgoaiId" style="background-color: white">
                                    <option :value="itemz.value" v-for="itemz in optionsQuocGia" v-bind:key="itemz.id">
                                      {{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.mucDich_NoiDungHoatDong"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <select disabled class="form-control" v-model="it.nganhLinhVucKeuGoiDauTuId" style="background-color: white">
                                    <option :value="itemz.value" v-for="itemz in optionsNganhLinhVuc"
                                            v-bind:key="itemz.id">{{ itemz.label }}
                                    </option>
                                  </select>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.diaBanKeuGoiDauTu"/>
<!--                                  <select disabled class="form-control" v-model="it.diaBanKeuGoiDauTu" style="background-color: white">-->
<!--                                    <option :value="itemz.value" v-for="itemz in optionsDiaBanKeuGoiDauTu"-->
<!--                                            v-bind:key="itemz.id">{{ itemz.label }}-->
<!--                                    </option>-->
<!--                                  </select>-->
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.canCuTrienKhai"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.toChucCoQuan_TrongNuoc"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.toChucCoQuan_NuocNgoai"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.doanhNghiep_TrongNuoc"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="text" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.doanhNghiep_NuocNgoai"/>
                                </td>
<!--                                <td class="text-center p-1 fs-13 align-middle w-120">-->
<!--                                  <select disabled class="form-control" v-model="it.nguonKinhPhi" style="background-color: white">-->
<!--                                    <option :value="itemz.value" v-for="itemz in optionsNguoiKinhPhi"-->
<!--                                            v-bind:key="itemz.id">{{ itemz.label }}-->
<!--                                    </option>-->
<!--                                  </select>-->
<!--                                </td>-->
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="tel" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.kinhPhi_NganSachDiaPhuong"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  <input disabled type="tel" class="form-control" style="background-color: white"
                                         placeholder=""
                                         v-model="it.kinhPhi_Khac"/>
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-120">
                                  {{ Number(it.kinhPhi_NganSachDiaPhuong) + Number(it.kinhPhi_Khac) | formatNumber }}
                                </td>
                                <td class="text-center p-1 fs-13 align-middle w-90px">
                                  <span v-if="it.trangThai === 1">Ban đầu</span>
                                  <span v-if="it.trangThai === 2">Điều chỉnh</span>
                                  <span v-if="it.trangThai === 3">Bổ sung</span>
                                  <span v-if="it.trangThai === 4">Hủy bỏ</span>
                                  <span v-if="!it.trangThai">Mới</span>
                                </td>
                              </tr>
                            </template>
                            <template v-else>
                              <tr>
                                <td class="text-center p-1 fs-13 align-middle" colspan="20">Chưa có dữ liệu</td>
                              </tr>
                            </template>
                          </template>
                        </vue-scrolling-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-secondary" @click="toggleModal({show: false})">Hủy bỏ</button>
        <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
      </template>
    </BaseModal>
</template>

<script>
import * as types from '@/store/modules/DeXuatCTXTDT_DiaPhuong/actionTypes'
import { mapGetters, mapActions } from 'vuex'
import VueScrollingTable from 'vue-scrolling-table'
import moment from 'moment'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
// import { loaiHoatDongService } from '@/services/loai-hoat-dong.service'
import { enums } from '@/shared/enums'

export default {
  name: 'DeXuatCTXTDTDiaPhuongPhuLucDetailModal',
  // props: ['item'],
  components: {
    VueScrollingTable
    // FileUpload
  },
  data () {
    return {
      Attachments: [],
      type: 'DeXuatCTXTDT_DiaPhuong',
      chiTietDeXuatCTXTDT: [],
      detailItem: {
        chiTietDeXuatCTXTDT: []
      },
      optionsNganhLinhVuc: [],
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsLoaiHoatDong: [],
      optionsNguoiKinhPhi: [],
      optionsDiaBanKeuGoiDauTu: []
    }
  },
  computed: {
    ...mapGetters('deXuatCTXTDTDiaPhuong', {
      item: 'detailItem',
      isPhuLucModalOpen: 'isPhuLucDetailModalOpen'
    })
  },
  methods: {
    ...mapActions('deXuatCTXTDTDiaPhuong', { toggleModal: types.TOGGLE_DETAIL_MODAL }),
    getDay: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'YYYY-MM-DD') => time ? moment(time, format).format('YYYY') : '',
    print () {
      const data = {
        item: this.item,
        chiTietDeXuatCTXTDT: this.detailItem.chiTietDeXuatCTXTDT
      }
      localStorage.setItem('de_xuat_ct_xtdt_dia_phuong_phu_luc', JSON.stringify(data))
      const routeData = this.$router.resolve({ path: '/de-xuat-ct-xtdt-dia-phuong/bieu-in-phu-luc' })
      window.open(routeData.href, '_blank')
    }
  },
  async mounted () {
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    // this.optionsLoaiHoatDong = await loaiHoatDongService.getAll()
    this.optionsLoaiHoatDong = enums.loaiHoatDongDeXuatCTXTDT
    this.optionsNguoiKinhPhi = enums.nguonKinhPhi
    this.optionsDiaBanKeuGoiDauTu = enums.diaBanKeuGoiDauTuDeXuatCTXTDT
  },
  watch: {
    item: function (val) {
      this.detailItem = val
    }
  }
}
</script>

<style scoped>

</style>
